import UserOverview from 'components/common//UserOverview'
import styles from 'components/common/Header.module.scss'
import MainMenu from 'components/common/MainMenu'
import { useLoginState } from './provider/LoginStateProvider'
import logo from 'images/d1p_logo.svg'
import logoImage from 'images/d1p_logo_only_image.svg'
import coopName from 'images/coop_name_png.png'
import HamburgerMenu from './HamburgerMenu'
import { useWindowDimension } from 'utils/windowUtils'
import { useEffect, useState } from 'react'

/**
 * ページヘッダー
 * @returns
 */
const Header = () => {
  const { isLogin, isAdmin } = useLoginState()
  const [winWidth] = useWindowDimension()
  const [isNarrow, setIsNarrow] = useState<boolean>(false)

  useEffect(() => {
    setIsNarrow(winWidth < 600 ? true : false)
  }, [winWidth])

  const title = isAdmin() ? '利用者ポータル管理' : '利用者ポータル'

  return (
    <section className={styles.headerContainer}>
      <div className={styles.productArea}>
        {/* 企業ロゴ */}
        {isNarrow && (
          <div className={styles.productLogo}>
            <img src={logoImage} className={styles.logoImage} alt={'企業イメージ'} />
            <img src={coopName} className={styles.coopImage} alt={'企業名'} />
          </div>
        )}
        {!isNarrow && (
          <div className={styles.productLogo}>
            <img src={logo} className={styles.logo} alt={'企業ロゴ'} />
          </div>
        )}
        {/* アプリ名 */}
        <div className={styles.productName}>{title}</div>
        {/* 企業情報 */}
        <div className={styles.userOverviewInfo}>
          {isLogin && <UserOverview />}
          {!isLogin && <div className={styles.notLoginUserOverview} />}
        </div>
        {/* ハンバーガーメニュー */}
        {isLogin && (
          <div className={styles.hamburgerMenu}>
            <HamburgerMenu />
          </div>
        )}
      </div>
      {/* メインメニュー */}
      {isLogin && (
        <div className={styles.mainMenu}>
          <MainMenu />
        </div>
      )}
    </section>
  )
}

export default Header
