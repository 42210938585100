import axios, { AxiosError, AxiosResponse } from 'axios'

const instance = axios.create({
  // ページ自体へのアクセスはHTTPS, APIリクエストはサーバ内部でhttps
  // -> httpに転送されており、プロトコル部を指定するとエラーが発生する。
  baseURL: '//api.1stbase.jp/',
  // baseURL: 'http://localhost:9090',
  // baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})
instance.interceptors.response.use(
  (res: AxiosResponse<any, any>) => {
    return res
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

export const AXIOS_CONFIG = {
  withCredentials: true
}

export default instance
